.formWrap {
  width: 100%;
}

.inputField {
  margin: 0;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.input {
  display: flex;
  height: 46px;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  font-weight: 400;
  width: 100%;

  border: 1px solid var(--black-txt);
  background: var(--white-text);
}
  
.inputQuestion {
  height: 200px;
  resize: none;
  font-family: Arial;
  font-size: 16px;
  width: 100%;
}

.btnWrap {
  display: flex;
  justify-content: center;
}

.btn {
  composes: buttonCommon from "../Button/Button.module.css";

  margin: 0;
  width: 120px;
  height: 32px;
  text-align: center;

  border: none;
  background-color: var(--yellow-accent);
  color: var(--white-text);
  transition: background-color 250ms var(--transition);
}

.btn:hover,
.btn:focus{
  cursor: pointer;
  background-color: var(--yellow-accent-hover);
}

.btn:active{
  background-color: var(--yellow-accent-click);
}
  
.error {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--red-error);
}

.inputError {
  border-color: var(--red-error);
}

.photoList{
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.photoWrap {
  position: relative;
  width: 68px;
}

.photo {
  width: 100%;
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  /* color:var(--black-text); */
  color: var(--blue-main);
  /* background-color: brown; */
  background-color: var(--white-text);
  /* background-color: rgba(245, 240, 193); */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  padding: 0;
  /* transition: background-color 250ms var(--transition); */
  transition: color 250ms var(--transition);
}

.close:hover{
  background-color: rgba(245, 240, 193);
  color: var(--yellow-accent);
}

.inputFiles {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.labelFiles {
  display:block;
  padding: 7px 14px;
  margin: 0;
  width: 200px;
  height: 32px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;

  border: 1px solid var(--blue-main);
  background-color: transparent;
  color: var(--blue-main);
  transition: background-color 250ms var(--transition),
  color 250ms var(--transition),
  border 250ms var(--transition);
}

.labelFiles:hover,
.labelFiles:focus{
  cursor: pointer;
  background-color: rgba(245, 240, 193, 0.767);
  color: var(--yellow-accent);
  border: 1px solid var(--yellow-accent);
}

.labelFiles:active{
  background-color: var(--yellow-accent-click);
  color: var(--yellow-accent);
}

.icon {
  fill:currentColor;
  width: 8px;
}

@media screen and (min-width: 320px) {
  .btn {
    width: 160px;
  }  

  .photoWrap {
    width: 88px;
  }

  .close {
    width: 20px;
    height: 20px;
  }

  .icon {
    width: 12px;
  }
}

@media screen and (min-width: 768px) {
  .inputField {
    margin-bottom: 52px;
  }

  .btn {
    width: 200px;
  }

  .labelFiles {
    width: 240px;
  }

  .photoWrap {
    width: 180px;
  }

  .close {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 1440px) {
  .btn {
    width: 240px;
  }  

  .photoWrap {
    width: 280px;
  }
}
  

  












