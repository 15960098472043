header {
  width: 100%;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.header_blue{
  background-color: var(--blue-main);
}

.wrap{
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .header {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
    
@media screen and (min-width: 1440px) {
  .header {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .wrap {
    justify-content: center;
    gap: 30px;
  }
}

.navWrap {
  display: flex;
  align-items: center;
}
