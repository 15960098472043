.container {
    display: none;
}

@media (min-width: 1440px) {
    .container {
        display: flex;
        gap: 40px;
        margin-right: 30px;
    }
}

.link {
    color: var(--white-text);
    transition: color 250ms var(--transition);
    font-weight: 600;
}

.link:hover{
    color: var(--yellow-accent-hover);
}

.active {
    border-bottom: 1px solid #FFF;
}